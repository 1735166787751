import React, { useEffect, useState } from 'react';
import order from 'utils/helpers/order';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';

function PasswordInput({ onChange, placeholder, inputLight }) {
  const [showPassword, setShowPassword] = useState(false);
  const [inputPassword, setInputPassword] = useState('');

  useEffect(() => {
    setInputPassword('');
  }, []);

  return (
    <div
      className={`d-row items-center border ${
        inputLight ? 'border-gray-300' : 'border-gray-600'
      } rounded-md overflow-visible`}>
      <input
        className="text-xs tracking-wide font-inter-regular rounded-md focus:appearance-none w-full py-2 px-1.5 text-gray-700 focus:outline-none"
        name="password"
        id="password"
        placeholder={placeholder}
        type={showPassword ? 'text' : 'password'}
        onChange={e => {
          setInputPassword(e.target.value);
          onChange(e);
        }}
        required
        value={inputPassword}
      />
      <button
        type="button"
        onClick={e => {
          e.preventDefault();
          setShowPassword(!showPassword);
        }}>
        <FontAwesomeIcon
          icon={showPassword ? faEyeSlash : faEye}
          color={'#000'}
          size="1x"
          className="hover:bg-gray-200 mx-1 cursor-pointer"
        />
      </button>
    </div>
  );
}

export default PasswordInput;
