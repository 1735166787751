import { useCallback, useContext } from 'react';
import { StoreContext } from 'index';
import useEmployeeId from 'utils/helpers/user';
import { useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const useFreshChatMethods = () => {
  const store = useContext(StoreContext);
  const { defaultRestaurant } = new useEmployeeId();
  let query = useQuery();

  const openBagChat = useCallback((bagNumber, destinationCode, cutoff) => {
    store.orderStore.setCurrentPath({
      pathName: '/order',
      help: true,
      destinationCode: destinationCode,
      orderDeadline: cutoff,
      bagNumber,
    });
  }, []);

  const openOrderChat = useCallback((destinationCode, cutoff) => {
    store.orderStore.setCurrentPath({
      pathName: '/order',
      help: true,
      destinationCode: destinationCode,
      orderDeadline: cutoff,
    });
  });

  const openReportProblemChat = useCallback(() => {
    if (window?.fcWidget?.isOpen() != true) {
      window?.fcWidget?.open();
    }
  });

  const resetFreshChat = useCallback(() => {
    store.orderStore.setCurrentPath({ pathName: '/order' });
    window?.fcWidget?.destroy();
  }, []);

  return { openBagChat, openReportProblemChat, resetFreshChat, openOrderChat };
};
